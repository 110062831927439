import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { usePost } from 'seed/api';
import styles from 'styles/css/calcs/Criterion.module.css';
import { Loading, MultiField } from "seed/helpers";
import { Formik, Field, Form } from "formik";
import { DEV_MODE } from 'settings';


const annualAdjustments = [{ value: "VI", text: "CAPÍTULO VI" }, { value: "IX", text: "CAPÍTULO IX" }];
const exchangesGain = [
  { value: "daily_1", text: "DIARIA TC (DÍA-DÍA ANTERIOR)" },
  { value: "daily_2", text: "DIARIA TC (DÍA-DÍA SIGUIENTE)" },
  { value: "monthly_1", text: "MENSUAL TC (PRIMER DÍA-ÚLTIMO DÍA)" },
  { value: "monthly_2", text: "MENSUAL TC (PRIMER DÍA-PRIMER DÍA SIGUIENTE)" },
  { value: "annually_1", text: "ANUAL TC (PRIMER DÍA-ÚLTIMO DÍA)" },
  { value: "annually_2", text: "ANUAL TC (ÚLTIMO DÍA-ÚLTIMO DÍA)" }
]

const shareAlienations = [
  { value: "historical", text: "TC HISTÓRICO" },
  { value: "sale", text: "TC VENTA" },
  { value: "sale_without", text: "TC VENTA SIN ACTUALIZACIÓN" }
]

const bondAlienations = [
  { value: "sale", text: "TC VENTA" },
  { value: "historical", text: "TC HISTÓRICO" },
]

const ofdAlienations = [
  { value: "historical", text: "TC HISTÓRICO" },
  { value: "sale", text: "TC VENTA" },
  { value: "sale_without", text: "TC VENTA SIN ACTUALIZACIÓN" }
]

const privateEquities = [
  { value: "mxn", text: "MXN CON ACTUALIZACIÓN" },
  { value: "usd_inf", text: "USD CON ACTUALIZACIÓN" },
  { value: "usd", text: "USD SIN ACTUALIZACIÓN" }
]

let defaultValue = {
  include_national: "false",
  include_foreign: "true",
  annual_adjustment: annualAdjustments[0].value,
  exchange_gain: exchangesGain[0].value,
  share_alienation: shareAlienations[0].value,
  bond_alienation: bondAlienations[0].value,
  share_alienation_rmf: "false",
  ofd_alienation: ofdAlienations[0].value,
  private_equity:  privateEquities[0].value,
  option_factor: "0.0",
  include_dividends: "true"
}

if (!DEV_MODE)
  delete defaultValue.private_equity;

function Panel(props) {
  const { declaration_id } = props.match.params;
  const { path, url } = props.match;
  const { showFullFeatures } = props;
  const userId = sessionStorage.getItem("id");

  const [submitType, setSubmitType] = useState("PREVIEW");
  const [loading, setLoading] = useState(false);


  const [cMonitor, qMonitor] = usePost("/processes/status", {
    onCompleted: data => {
      if (data.status == "IN_PROCESS"){
        setLoading(true)
        setTimeout(() => cMonitor({uuid: data.uuid}), 5000)
        return
      }
      setLoading(false)
      if (data.status == "OK"){
        if (data.type == "EXEC_CALC"){
          window.location.href = data.result["url"];      
          alert("Cálculo completado");
          setTimeout(() => window.location.replace("/"), 500)          
        }
      } else
        alert("Ha ocurrido un error, prueba de nuevo")
      
    },
    onError: data => alert("Ha ocurrido un error, prueba de nuevo")
  });

  const [cPost, qPost] = usePost("/calcs/exec", {
    onCompleted: data => cMonitor({uuid: data.uuid}),
    onError: () => alert("Error ejecutando cálculo")
  });

  const [cPostExport, qPostExport] = usePost("/calcs/export_pdf", {
    onCompleted: data => {
      alert("Exportación en proceso: En cuando quede listo el reporte, te enviaremos una notificación por correo electrónico - Tiempo aproximado: 20min")
      window.location.replace("/");
    },
    onError: () => alert("Error ejecutando cálculo")
  });

  
  if (loading) return (
    <div className={styles.module}>
      <div className={cx(styles.paperLoading, styles.flexCenter)}>
        <Loading />
      </div>
    </div>);


  const onClickExec = (values) => {
    const userId = sessionStorage.getItem("id")
    if (submitType == "PREVIEW") values = defaultValue
    values.include_national = values.include_national == "true"
    values.include_foreign = values.include_foreign == "true"
    values.share_alienation_rmf = values.share_alienation_rmf == "true"
    values.include_dividends = values.include_dividends == "true"
    if (!values.include_national && !values.include_foreign && submitType != "PREVIEW")
      return alert("Selecciona al menos un tipo de cálculo");

    if (submitType == "PREVIEW") 
      cPost({ declaration_id: declaration_id, criteria: values, is_preview: true })
    else if (submitType == "GENERATE")
      cPost({ declaration_id: declaration_id, criteria: values })
    else if (submitType == "EXPORT") {
      let confirm = window.confirm("Debido al tiempo de generación, recomendamos exportar el cálculo en la versión final")
      if (confirm)
        cPostExport({ declaration_id: declaration_id, user_id: userId, criteria: values })
      else window.location.href = url
    }
  }

  return (
    <div className={styles.module}>
      <div className={cx(styles.jumbotron)}>
        <h4>Ejecución de cálculo</h4>
      </div>

      <div className={cx(styles.paper, styles.flexCol)}>
        <div className={cx(styles.modalDeclaration, styles.flexCol)}>


          <Formik
            initialValues={defaultValue}
            onSubmit={onClickExec}>
            {({ values, setFieldValue }) =>
              <Form>
                
                
                <div className={cx(styles.step)}>
                  <div className={cx(styles.vStepper)}>
                    <div className={cx(styles.circle)}></div>
                    <div className={cx(styles.line)}></div>
                  </div>

                  <div className={cx(styles.content)}>
                    <b> PASO 1: Selección de criterios </b>

                    <button onClick={() => setSubmitType("PREVIEW")}
                      className={cx(styles.btn, styles.btnGrey, styles.fullBtn)} style={{ marginTop: "10px" }}>
                      <span className={styles.text}>Generar cálculo preliminar</span>
                    </button>

                  </div>
                </div>


                <div className={cx(styles.step)}>
                 
                  <div className={cx(styles.vStepper)}>
                    <div className={cx(styles.circle)}></div>
                    <div className={cx(styles.line)}></div>
                  </div>

                  <div className={cx(styles.content)} style={{marginLeft: "20px"}}>
                    <b style={{ marginBottom: "15px", display: "block" }}>  PASO 2: Generación de cálculo</b>





                      <div>
                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="include_national">Cálculo - ingresos nacionales</label>

                            <Field as="select" name="include_national" id="include_national"
                              className={styles.select} required>
                              <option value="true">Si</option>
                              <option value="false">No</option>
                            </Field>
                          </div>
                        </div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="include_foreign">Cálculo - ingresos extranjeros</label>

                            <Field as="select" name="include_foreign" id="include_foreign"
                              className={styles.select} required>
                              <option value="true">Si</option>
                              <option value="false">No</option>
                            </Field>
                          </div>
                        </div>
                        <hr style={{ margin: "30px 10px" }} />
                      </div>

                    {values.include_foreign == "true" ?
                      <div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="annual_adjustment">Ajuste anual</label>

                            <Field as="select" name="annual_adjustment" id="annual_adjustment"
                              className={styles.select} required>
                              {annualAdjustments.map((item) => <option value={item.value} >{item.text}</option>)}
                            </Field>
                          </div>
                        </div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="exchange_gain">Ganancia cambiaria</label>

                            <Field as="select" name="exchange_gain" id="exchange_gain"
                              className={styles.select} required>
                              {exchangesGain.map((item) => <option value={item.value} >{item.text}</option>)}
                            </Field>
                          </div>
                        </div>

                        <hr style={{ margin: "30px 10px" }} />

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="share_alienation">Enajenación de acciones</label>

                            <Field as="select" name="share_alienation" id="share_alienation"
                              className={styles.select} required>
                              {shareAlienations.map((item) => <option value={item.value} >{item.text}</option>)}
                            </Field>
                          </div>
                        </div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="share_alienation_rmf">Enajenación de acciones RMF</label>

                            <Field as="select" name="share_alienation_rmf"
                              className={styles.select} id="share_alienation_rmf" required>
                              <option value="true">Si</option>
                              <option value="false">No</option>
                            </Field>
                          </div>
                        </div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="bond_alienation">Enajenación de bonos</label>

                            <Field as="select" name="bond_alienation" id="bond_alienation"
                              className={styles.select} required>
                              {bondAlienations.map((item) => <option value={item.value} >{item.text}</option>)}
                            </Field>
                          </div>
                        </div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="ofd_alienation">Enajenación de OFDs</label>

                            <Field as="select" name="ofd_alienation" id="ofd_alienation"
                              className={styles.select} required>
                              {ofdAlienations.map((item) => <option value={item.value} >{item.text}</option>)}
                            </Field>
                          </div>
                        </div>

                        { DEV_MODE ?
                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="private_equity">Private equity</label>

                            <Field as="select" name="private_equity" id="private_equity"
                              className={styles.select} required>
                              {privateEquities.map((item) => <option value={item.value} >{item.text}</option>)}
                            </Field>
                          </div>
                        </div> : null}

                        <hr style={{ margin: "30px 10px" }} />


                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="option_factor">Factor opción 239 RLISR</label>
                            <Field name="option_factor" id="option_factor" type="number"
                              placeholder="" className={cx(styles.input)} required />
                          </div>
                        </div>

                        <div className={cx(styles.flex, styles.jump)}>
                          <div className={cx(styles.flexFill, styles.marginRight)}>
                            <label for="include_dividends">Incluir tope 10% dividendos</label>

                            <Field as="select" name="include_dividends"
                              className={styles.select} id="include_dividends" required>
                              <option value="true">Si</option>
                              <option value="false">No</option>
                            </Field>
                          </div>
                        </div>
                      </div> : null
                    }

                    <button type="submit" onClick={() => setSubmitType("GENERATE")}
                      className={cx(styles.btn, styles.btnGreen, styles.fullBtn)} style={{ marginTop: "10px" }}>
                      <span className={styles.text}>Generar cálculo</span>
                    </button>

                  </div>
                </div>

                <div className={cx(styles.step)}>
                  <div className={cx(styles.vStepper)}>
                    <div className={cx(styles.circle)}></div>
                  </div>
                  
                  { showFullFeatures ?
                  <div className={cx(styles.content)} style={{marginLeft: "20px" }}>
                    <b style={{ marginBottom: "15px", display: "block" }}> PASO 3 (Opcional): Exportación a PDF</b>

                    <button type="submit" onClick={() => setSubmitType("EXPORT")}
                      className={cx(styles.btn, styles.btnExport, styles.fullBtn)}>
                      <span className={styles.text}>Exportar cálculo a PDF</span>
                    </button>

                  </div> : null}
                </div>

              </Form>
            }</Formik>

        </div>
      </div>
    </div>
  );
}

export default Panel;